import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { graphql, navigate } from "gatsby"

import SiteLayout from "../components/SiteLayout"
import Seo from "../components/Seo"

import { toggleBackground } from "../state/background"
import { setBreadcrumbs } from "../state/breadcrumbs"
import { toggleStickyHeaderColour } from "../state/header"

import Hero from "../components/Hero"
import ProjectItem from "../components/ProjectItem"

const ProjectsPage = props => {
  const projectsContainer = useRef(null)
  const [singleProjectHeight, setSingleProjectHeight] = useState(null)
  const [windowWidth, setWindowWidth] = useState(null)

  const {
    data,
    location,
    pageContext: { pageNumber },
    transitionStatus,
    dispatch,
  } = props
  const projectPageNumber = pageNumber ? ` Page ${pageNumber}` : ``
  const totalProjects = data.allWpProject.edges.length
  const totalCols = 3

  useEffect(() => {
    window.addEventListener("resize", event => {
      setWindowWidth(window.innerWidth)
    })

    return (
      // Remove window event listener on component unmount
      window.removeEventListener("resize", event => {
        setWindowWidth(window.innerWidth)
      })
    )
  }, [])

  useEffect(() => {
    // Update state with bg colour
    dispatch(toggleBackground("white"))
    setTimeout(() => {
      dispatch(toggleStickyHeaderColour("white"))
    }, 1000)

    // update breadcrumbs state
    dispatch(
      setBreadcrumbs({
        current: {
          title: `Work`,
          path: `/work`,
        },
      })
    )
  }, [])

  useEffect(() => {
    if (projectsContainer?.current) {
      setSingleProjectHeight(0)

      // Allow time for rendering on first page load
      // in order to get correct height of the el
      setTimeout(() => {
        // Get the tallest project item and update all heights declaratively
        const projectsElBody = projectsContainer.current.querySelectorAll(
          ".card__body"
        )
        const projectHeights = Array.from(projectsElBody).map(item => {
          return item.clientHeight
        })
        const tallestProjectHeight = Math.max(...projectHeights)

        setSingleProjectHeight(tallestProjectHeight)
      }, 200)
    }
  }, [windowWidth])

  const renderPreviousLink = () => {
    const {
      pageContext: { pageNumber },
    } = props

    let previousLink = null

    if (!pageNumber) {
      return null
    } else if (1 === pageNumber) {
      previousLink = `/work`
    } else if (1 < pageNumber) {
      previousLink = `/work/${pageNumber - 1}`
    }

    return (
      <button type="primary" onClick={() => navigate(previousLink)}>
        Previous
      </button>
    )
  }

  const renderNextLink = () => {
    const {
      pageContext: { hasNextPage, pageNumber },
    } = props

    if (hasNextPage) {
      return (
        <button
          type="primary"
          onClick={() => navigate(`/work/${pageNumber + 1}`)}
        >
          Next
        </button>
      )
    } else {
      return null
    }
  }

  return (
    <SiteLayout
      pageNumber={pageNumber}
      location={location}
      transitionStatus={transitionStatus}
    >
      <Seo seoTitle={`Work | Broadgate`} />

      <Hero
        slogan={data.allWp.edges[0].node.options.options.workIntroduction}
        halfHeight
        transitionStatus={transitionStatus}
      />

      <div
        ref={projectsContainer}
        id="main"
        className="main-content main-content--half"
      >
        <section className="section section--pure-white">
          <div className="container-fluid">
            <div className="row">
              {data &&
                data &&
                data.allWpProject.edges.map(({ node }, i) => {
                  const { id, slug, projectFields } = node
                  return (
                    <div key={id} className="col-bp1-12 col-bp2-6 col-bp4-4">
                      <ProjectItem
                        index={i}
                        slug={slug}
                        projectLogo={projectFields.projectLogoLightMode}
                        shortDesc={projectFields.projectShortDesc}
                        totalProjects={totalProjects}
                        totalCols={totalCols}
                        childLink
                        services={projectFields.projectSubnav}
                        height={singleProjectHeight}
                        previewImage={projectFields.projectPreviewImage}
                      />
                    </div>
                  )
                })}
              {renderPreviousLink()}
            </div>
          </div>
        </section>
      </div>
    </SiteLayout>
  )
}

export default connect(
  state => ({
    background: state.background.background,
    breadcrumbs: state.breadcrumbs.breadcrumbs,
  }),
  null
)(ProjectsPage)

export const query = graphql`
  query GET_PROJECTS($ids: [String]) {
    allWp {
      edges {
        node {
          options {
            options {
              workIntroduction
            }
          }
        }
      }
    }
    allWpProject(filter: { id: { in: $ids } }) {
      edges {
        node {
          id
          title
          slug
          projectFields {
            projectLogoLightMode {
              altText
              sourceUrl
            }
            projectPreviewImage {
              altText
              sourceUrl
              mimeType
              localFile {
                childImageSharp {
                  fluid(
                    maxHeight: 480
                    maxWidth: 680
                    quality: 85
                    cropFocus: CENTER
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            projectShortDesc
            projectSubnav {
              projectSubnavItem {
                ... on WpPage {
                  id
                  title
                  uri
                  parent {
                    ... on WpPage {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
